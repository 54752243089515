import { observer } from "mobx-react";
import { useEffect, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";

import Button from "components/UI/Button/Button";
/* import FrozenBalance from "./FrozenBalance/FrozenBalance.jsx"; */

import AppStore from "AppStore.js";
import dropDownMenuStore from "./DropDownMenuStore";
import popupStore from "components/Popup/PopupStore";

import { EVENTS, metricsEvent } from "metrics";

import "./DropDownMenu.scss";

export const DropDownMenu = observer(({ isMobile, headerForDesktop }) => {
  const dropdownRef = useRef(null);
  const navigate = useNavigate();

  const dropdownMenuStyles = {
    position: isMobile && !headerForDesktop ? "fixed" : "absolute",
    top: isMobile && !headerForDesktop ? "50%" : "75px",
    bottom: isMobile && !headerForDesktop ? "auto" : "auto",
    right: isMobile && !headerForDesktop ? "0" : "0",
    left: isMobile && !headerForDesktop ? "50%" : "auto",
    transform: isMobile && !headerForDesktop ? "translate(-50%, -50%)" : "none", // Center the element
    zIndex: "15",
    padding: isMobile && !headerForDesktop ? "16px" : "31px 15px 23px",
    minWidth: isMobile && !headerForDesktop ? "250px" : "310px", 
    display: "flex",
    flexDirection: "column",
    backgroundColor: "#fff",
    borderRadius: "10px",
    boxShadow: isMobile && !headerForDesktop ? "0 8px 25px -5px rgba(0, 47, 67, 0.75)" : "0 8px 25px -5px rgba(0, 47, 67, 0.15)",
    opacity: dropDownMenuStore.isActive ? "1" : "0",
    transition: "all 0.4s",
    pointerEvents: dropDownMenuStore.isActive ? "all" : "none",
  };

  const handleCloseDropdown = (e) => {
    e.stopPropagation();
    dropDownMenuStore.close();
  };

  const handleLogout = () => {
    AppStore.setToken(null);
    AppStore.setHidePaymentNotification(false);
    localStorage.removeItem('hidePaymentNotification');
    navigate("/");
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        dropDownMenuStore.close();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div
      className={
        "dropdownmenu" + (dropDownMenuStore.isActive ? " active" : "")
      }
      style={dropdownMenuStyles}
      ref={dropdownRef}
    >
      <div className="dropdownmenu__close" onClick={handleCloseDropdown} />
      <div className="dropdownmenu__balance-container">
        <div className="dropdownmenu__balance-content">
          <div className="dropdownmenu__balance">
            <p className="dropdownmenu__balance-text">Баланс</p>
            <p className="dropdownmenu__balance-digit">
              {dropDownMenuStore.balance.toLocaleString()} ₽
            </p>
          </div>
          <Button
            title="Пополнить"
            classes={["dropdownmenu__button"]}
            onClick={() => {
              metricsEvent(EVENTS.DEPOSIT_OPEN);
              popupStore.open("paymodal");
            }}
          />
        </div>
        {/* <FrozenBalance store={dropDownMenuStore} /> */}
      </div>
      <Link
        to="/projects"
        className="dropdownmenu__link"
        onClick={dropDownMenuStore.close}
      >
        Мои проекты
      </Link>
      <div className="dropdownmenu__link" onClick={handleLogout}>Выход</div>
    </div>
  );
});