import { observer } from "mobx-react";

import { Popup } from "components/Popup/Popup";
import Button from "../Button/Button.jsx";
import { ButtonWithIcon } from "../ButtonWithIcon/ButtonWithIcon.jsx";

import popupStore from "components/Popup/PopupStore";

import stopIcon from "assets/images/stop.svg";
import stopmodalIcon1 from "assets/images/stopmodal-icon1.svg";
import stopmodalIcon2 from "assets/images/stopmodal-icon2.svg";
import stopmodalIcon3 from "assets/images/stopmodal-icon3.svg";
import stopmodalIcon4 from "assets/images/stopmodal-icon4.svg";
import stopmodalIcon5 from "assets/images/stopmodal-icon5.svg";

import "./StopProjectModal.scss";

const infoPoints = [
  { icon: stopmodalIcon1, text: "Задания без исполнителя отменяются сразу" },
  { icon: stopmodalIcon2, text: "По мере завершения отменяются задания, которые уже взяли в работу" },
  { icon: stopmodalIcon3, text: "На доработку задачи у исполнителя есть 24 часа" },
  { icon: stopmodalIcon4, text: "Дождитесь отчет — оплатите работу исполнителя или отклоните отчет, указав причину" },
  { icon: stopmodalIcon5, text: "Мы вернем деньги за непринятые задания" }
];

export const StopProjectModal = observer(() => {
  function cancel() {
    popupStore.cancel();
    popupStore.close();
  }

  function ok() {
    popupStore.ok();
    popupStore.close();
  }

  return (
    <Popup modalType="stopmodal">
      <div className="stopmodal__header">
        <div className="stopmodal__header-icon" />
        <div className="stopmodal__header-description">
          <p className="stopmodal__header-message">Остановить проект?</p>
          <p className="stopmodal__header-comment">
            Перед остановкой проекта вам нужно кое-что знать
          </p>
        </div>
      </div>
      <div className="stopmodal__info">
        {infoPoints.map(({ icon, text }, index) => (
          <div key={index} className="stopmodal__info_points-container">
            <div
              className="stopmodal__info_points-icon"
              style={{ backgroundImage: `url(${icon})` }}
            />
            <p>{text}</p>
          </div>
        ))}
      </div>
      <div className="stopmodal__buttons">
        <Button
          title="Не останавливать"
          className="stopmodal__button-cancel"
          onClick={cancel}
        />
        <ButtonWithIcon
          title="Остановить"
          className="stopmodal__button-ok"
          icon={stopIcon}
          ariaLabel="Согласен остановить проект"
          onClick={ok}
        />
      </div>
    </Popup>
  );
});
