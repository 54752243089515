import { useLocation } from "react-router-dom";

import classNames from "classnames";

import IntroAnimation from "../IntroAnimation/IntroAnimation";
import IntroPerformers from "../IntroPerformers/IntroPerformers";

import "./IntroMarkets.scss";

const PATHS = {
  YANDEX_MAPS: "/reviews-on-yandexmaps",
  AVITO: "/reviews-on-avito",
};

export default function IntroMarkets({ title }) {
  const { pathname } = useLocation();

  const getClassNames = () => {
    const isYandexMaps = pathname === PATHS.YANDEX_MAPS;
    const isAvito = pathname === PATHS.AVITO;

    return {
      "yandex-maps": isYandexMaps,
      avito: isAvito,
    };
  };

  const classes = getClassNames();

  return (
    <div className={classNames("intro-markets", classes)}>
      <IntroPerformers />
      <IntroAnimation className={classes} />
      <p className={classNames("intro-markets__title", classes)}>{title}</p>
      <div className={classNames("intro-markets__container", classes)}>
        {["message", "like", "eye", "human"].map((icon) => <div key={icon} className={classNames(`intro-markets__container-${icon}`, classes)} />)}
      </div>
      {(pathname === PATHS.YANDEX_MAPS || pathname === PATHS.AVITO) && <div className={classNames("intro-markets__info", classes)} />}
    </div>
  );
}
