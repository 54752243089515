import { InfoArticle } from "../InfoArticle/InfoArticle";
import { TimeArticle } from "../TimeArticle/TimeArticle";
import "./OtherArticle.scss";

export default function OtherArticle({ article }) {
  const { type, title, data, time } = article;

  return (
    <>
      <TimeArticle
        time={time}
        style={{
          marginTop: "8px",
          marginRight: "8px",
        }}
      />
      <div className="other-articles__info">
        <div className="other-articles__type">{type}</div>
        <h2 className="other-articles__title">{title}</h2>
        <InfoArticle data={data} />
      </div>
    </>
  );
}
