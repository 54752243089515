import { observer } from "mobx-react";
import { useState } from "react";

import ActivityList from "./ActivityList/ActivityList";
import MarketItem from "../MarketItem/MarketItem";

import { MARKETS } from "AppStore";

import "./ServicesSection.scss";

const ServicesSection = observer(({ store, isMobile }) => {
  const [animateActivityList, setAnimateActivityList] = useState(false);

  const handleMarketSelect = (index) => {
    setAnimateActivityList(true);
    store.selectMarketStoreByIndex(index);
    setTimeout(() => setAnimateActivityList(false), 300);
  };

  return (
    <section className="main__services">
      <div className="main__services_platforms">
        {MARKETS.map((market, index) => (
          <MarketItem
            key={index}
            market={market}
            index={index}
            onClick={() => { store.selectMarketStoreByIndex(index); handleMarketSelect(index); }}
            isSelected={index === store.currentMarketStoreIndex}
            containerClassName="main__services_platforms-items"
            itemClassName="main__services_platforms-item"
            logoClassName="main__services_platforms-logo"
            titleClassName="main__services_platforms-title"
          />
        ))}
      </div>
      <ActivityList store={store} isMobile={isMobile} animate={animateActivityList} />
    </section>
  );
});

export default ServicesSection;
