import { useLocation } from "react-router-dom";

import IntroMarkets from "./IntroMarkets/IntroMarkets";

import AppStore from "AppStore";

import { useMatchMedia } from "utils/hooks/use-match-media";

import "./Intro.scss";

export default function Intro({ title, children }) {
  const { isMobile } = useMatchMedia();
  const { pathname } = useLocation();

  const market = AppStore.currentMarketStore.market;

  return (
    <section className="intro">
      {isMobile ? (
        <div className="intro__info-container">
          <h1 className="intro__title">{title}</h1>
          <div className="intro__children-container">{children}</div>
        </div>
      ) : (
        <>
          <div className="intro__info-container">
            <h1 className="intro__title">{title}</h1>
            <div>{children}</div>
          </div>
          <div className="intro__container-markets">
            {AppStore.isMarketPage(pathname) && <IntroMarkets title={market.titlePlaces} />}
            <div className="intro__container-markets_big-circle" />
            <div className="intro__container-markets_small-circle" />
          </div>
        </>
      )}
    </section>
  );
}
