import { observer } from "mobx-react";

import { ActivitiesCalculator } from "../componentsStep2/ActivitiesCalculator/ActivitiesCalculator";
import ModeSelection from "../componentsStep2/ModeSelection/ModeSelection";
import { BudgetSlider } from "components/BudgetSlider/BudgetSlider";
import Deadline from "../componentsStep2/Deadline/Deadline";
import { Notice } from "../componentsStep2/Notice/Notice";
import Faqed from "components/UI/Faqed/Faqed";
import Button from "components/UI/Button/Button";

import { getCountActions, getCountDays } from "utils/helpers";

import "./IsDesktopStep2.scss";

export const IsDesktopStep2 = observer(
  ({
    store,
    calculator,
    pathname,
    reviewRef,
    commentRef,
    getDisplayCount,
    getAllActionCount,
    onClickNext,
  }) => {
    const { market, mode } = calculator;
    const notice = mode.notice;

    return (
      <div className="market-step2">
        <div className="market-step2__distribution">
          {store.calculators.length > 1 && <ModeSelection store={store} />}
          <Faqed text={mode.stepTwoTitleFaq}>
            <div className="market-step2-mobile__distribution-icon" style={{ backgroundImage: `url(${market.icon})` }} />
            <p className="market-step2__distribution-title">
              {mode.calculatorTitle}
            </p>
          </Faqed>
          <ActivitiesCalculator
            store={store}
            reviewRef={reviewRef}
            commentRef={commentRef}
          />
        </div>

        <div className="market-step2__settings">
          <div className="market-step2__settings-container">
            <div className="market-step2__budget-slider-container">
              <p className="market-step2__budget-slider-total-cost">Общая стоимость*</p>
              <div className="market-step2__budget-slider-action">
                <BudgetSlider store={store}>
                  <p className="market-step2__project-price-info">
                    {`${store.currentCalculator.price} ₽`}
                  </p>
                  <div className="market-step2__project-info">
                    <div className="market-step2__project-info_action">
                      <p>≈</p>
                      <div className="market-step2__project-info_action-icon" />
                      <p>{`${getAllActionCount(store.currentCalculator, pathname)} ${getCountActions(getAllActionCount(store.currentCalculator, pathname))}`}</p>
                    </div>
                    {getDisplayCount(store.currentCalculator, pathname) && (
                      <div className={`market-step2__project-info_reviews ${mode.actions.find((action) => action.type.includes("REVIEW")) ? "" : "hidden"}`}>
                        <p>≈</p>
                        <div className="market-step2__project-info_reviews-icon" />
                        <p>{getDisplayCount(store.currentCalculator, pathname)}</p>
                      </div>
                    )}
                  </div>
                </BudgetSlider>
              </div>
              <div className="market-step2__deadline">
                <p className="market-step2__deadline-title">
                  Выберите скорость отправки заданий исполнителям:
                </p>
                <div className="market-step2__deadline-choice">
                  {store.currentCalculator.deadlines.map((deadline, i) => (
                    <Deadline
                      key={i}
                      deadline={`${deadline.days} ${getCountDays(
                        deadline.days
                      )}`}
                      emoji={deadline.emoji}
                      title={deadline.title}
                      text={deadline.text}
                      isActive={i === store.selectedDeadlineIndex}
                      onClick={() => {
                        store.selectDeadline(i);
                      }}
                    />
                  ))}
                </div>
              </div>
            </div>
            <Button
              className="market-step2__create-button"
              title="К следующему шагу"
              onClick={onClickNext}
            />
          </div>

          <Notice notice={notice} />
        </div>
      </div>
    );
  }
);
