import { observer } from "mobx-react";
import { Helmet } from "react-helmet";
import { useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";

import { SettingsStep } from "./SettingsStep/SettingsStep";
import { Step2 } from "./Step2/Step2";
import { SettingsSeoStep } from "./SettingsSeoStep/SettingsSeoStep";
// import MarketSelection from "./Step2/componentsStep2/MarketSelection/MarketSelection";
import Promo from "./Promo/Promo";
import IntroPerformers from "components/Intro/IntroPerformers/IntroPerformers";
import IntroWithPoints from "components/IntroWithPoints/IntroWithPoints";
import Answers from "components/Answers/Answers";

import { useMatchMedia } from "utils/hooks/use-match-media";

import AppStore from 'AppStore';

import "./Market.scss";

const Market = observer(() => {
  const [isExpanded, setIsExpanded] = useState(false);

  const { isMobile } = useMatchMedia();
  const location = useLocation();
  const step1Ref = useRef();
  const step2Ref = useRef();
  const lastHash = useRef("");
  const linkStep1Ref = useRef(null);
  const categoryStep1Ref = useRef(null);
  const cityStep1Ref = useRef(null);
  const searchPhrasesStep1Ref = useRef(null);
  const screenshotStep1Ref = useRef(null);
  const reviewStep2Ref = useRef(null);
  const commentStep2Ref = useRef(null);
  const brandPhrasesStep1Ref = useRef(null);

  const calculator = AppStore.currentMarketStore.currentCalculator;
  const { mode } = calculator;
  const meta = mode.meta;
  const isSettingsSeo = AppStore.currentMarketStore.calculators[0].market.isSeo;

  const toggleExpanded = () => {
    setIsExpanded(!isExpanded);
  };

  const scrollToError = (errorRef) => {
    if (errorRef.current) {
      const headerHeight = 100;
      const rect = errorRef.current.getBoundingClientRect();
      const offsetTop = rect.top + window.scrollY - headerHeight;
      window.scrollTo({ top: offsetTop, behavior: 'smooth' });
    }
  };

  useEffect(() => {
    if (location.hash) lastHash.current = location.hash.slice(1);

    if (!lastHash.current || !document.getElementById(lastHash.current))
      return;

    setTimeout(() => {
      document
        .getElementById(lastHash.current)
        ?.scrollIntoView({ behavior: "smooth", block: "start" });
      lastHash.current = "";
    }, 0);
  }, [location]);

  return (
    <main className="market">
      <Helmet>
        <title>{meta.title}</title>
        <meta name="description" content={meta.description} />
        <meta name="robots" content="all" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no" />
      </Helmet>

      <div className="market__inner">
        {!AppStore.isAuth && (
          <>
            <IntroWithPoints {...mode.intro} type={mode.id} />
            {isMobile &&
              <>
                <button className="intro__presentation" onClick={toggleExpanded}>
                  Как это работает?
                  {isExpanded && <Promo howItWorks={mode.howItWorks} />}
                </button>
                <IntroPerformers />
              </>
            }
          </>
        )}

        {!isMobile && <Promo howItWorks={mode.howItWorks} />}

        <div className="market__interactive">
          <div ref={step2Ref} className="market__interactive-title">
            <p>Шаг 1<span>/2:</span></p>
            <h2 className="market__interactive-title__steps-total">{mode.stepPhrase1}</h2>
          </div>
          <div>
            {/* <MarketSelection store={AppStore.currentMarketStore} /> */}
            <Step2
              key={isMobile ? "mobile-step2" : "desktop-step2"}
              store={AppStore.currentMarketStore}
              nextStep={step1Ref}
              reviewRef={reviewStep2Ref}
              commentRef={commentStep2Ref}
              scrollToError={scrollToError}
            />
          </div>
          <div ref={step1Ref} className="market__interactive-title">
            <p>Шаг 2<span>/2:</span></p>
            <h2 className="market__interactive-title__steps-total">{mode.stepPhrase2}</h2>
          </div>
          {isSettingsSeo ?
            <SettingsSeoStep
              store={AppStore.currentMarketStore}
              step1Ref={step2Ref}
              linkRef={linkStep1Ref}
              cityRef={cityStep1Ref}
              searchPhrasesRef={searchPhrasesStep1Ref}
              screenshotRef={screenshotStep1Ref}
              brandPhrasesRef={brandPhrasesStep1Ref}
              scrollToError={scrollToError}
            />
            :
            <SettingsStep
              key={isMobile ? "mobile-step1" : "desktop-step1"}
              store={AppStore.currentMarketStore}
              step1Ref={step2Ref}
              linkRef={linkStep1Ref}
              categoryRef={categoryStep1Ref}
              cityRef={cityStep1Ref}
              searchPhrasesRef={searchPhrasesStep1Ref}
              screenshotRef={screenshotStep1Ref}
              reviewRef={reviewStep2Ref}
              commentRef={commentStep2Ref}
              scrollToError={scrollToError}
            />
          }
        </div>

        <Answers title={mode.titleFAQ} questions={mode.faq} />
      </div>
    </main>
  );
});

export default Market;
