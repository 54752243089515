import { observer } from "mobx-react";
import { useEffect, useState } from "react";

import ActionLink from "./ActionLink/ActionLink";
import { ButtonWithIcon } from "components/UI/ButtonWithIcon/ButtonWithIcon";

import arrow from "assets/images/arrowRightBlack.svg";
import magicIcon from "assets/images/activities/magic.svg";

import "./ActivityList.scss";

const ActivityList = observer(({ store, isMobile, animate }) => {
  const [isAnimated, setIsAnimated] = useState(false);
  const { market, currentCalculator } = store.currentMarketStore;

  // TODO: why modes[0] ?
  const hasReviewAction = market.modes[0].actions.some(action => action.type === 'MAIN.REVIEW');

  const orderedActions = [
    market.modes[0],
    ...(hasReviewAction ? [{
      title: "Генерация отзыва от GPT",
      titleComment: "не пишите сами — GPT сделает это за вас",
      icon: magicIcon,
      path: 'https://ai.boostclick.ru/',
      target: '_blank',
      statusForHomePage: "new",
      isGPT: true,
    }] : []),
    ...market.modes.slice(1),
    ...market.modes[0].actions
  ];

  const combinedActions = orderedActions.map(action => ({
    ...action,
    icon: action.iconForHomePage || action.icon,
    isMode: action.isMode !== false
  })).slice(0, 9);

  useEffect(() => {
    setIsAnimated(true);
    const timer = setTimeout(() => setIsAnimated(false), 300);
    return () => clearTimeout(timer);
  }, [market]);

  return (
    <section className={`main__services_activity ${isAnimated ? 'animated' : ''}`}>
      {!isMobile && <p className="main__services_activity-title">Выберите интересующие вас действия на {market.title}:</p>}
      <div className={`main__services_activity-list ${isAnimated ? 'animated' : ''}`}>
        <div className="main__services_activity-list-column left">
          {combinedActions.slice(0, 5).map((action, index) => (
            <ActionLink
              key={index}
              action={action}
              path={action.path || currentCalculator.mode.path}
              isMobile={isMobile}
              isAnimated={isAnimated}
              marketTitle={market.title}
              isMode={action.isMode}
              status={action.statusForHomePage}
              target={action.target}
            />
          ))}
        </div>
        <div className="main__services_activity-list-column right">
          {combinedActions.slice(5).map((action, index) => (
            <ActionLink
              key={index + 5}
              action={action}
              path={action.path || currentCalculator.mode.path}
              isMobile={isMobile}
              isAnimated={isAnimated}
              marketTitle={market.title}
              isMode={action.isMode}
              target={action.target}
            />
          ))}
          <ButtonWithIcon
            key={market.id}
            title={`Заказать для ${market.title}`}
            to={currentCalculator.mode.path}
            icon={arrow}
            onClick={() => window.scrollTo(0, 0)}
            target="_self"
          />
        </div>
      </div>
    </section>
  );
});

export default ActivityList;