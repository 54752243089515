import { observer } from "mobx-react";
import { Link } from "react-router-dom";

import Button from "components/UI/Button/Button";
import { ButtonWithIcon } from "components/UI/ButtonWithIcon/ButtonWithIcon";
import { DropDownMenu } from "components/DropDownMenu/DropDownMenu";
import { DropdownLinks } from "components/DropDownLinks/DropDownLinks";

import servicesDisIcon from "assets/images/header/servicesIcon-dis.svg";
import closeIcon from "assets/images/header/closeIconServices.svg";
import projectsIcon from "assets/images/header/projectsIcon.svg";
import projectsIconDis from "assets/images/header/projectIcon-dis.svg";
// import blogIcon from "assets/images/header/blogIcon.svg";
// import blogIconDis from "assets/images/header/blogIcon-dis.svg";
import faqIcon from "assets/images/header/faqIcon-dis.svg";
import lkIcon from "assets/images/header/lkMobileIcon.svg";
import lkIconDis from "assets/images/header/lkMobileIcon-dis.svg";
import supportIcon from "assets/images/header/supportIcon.svg";
import loginIcon from "assets/images/header/loginMobile-dis.svg";

import AppStore from "AppStore";

import { EVENTS, metricsEvent } from "metrics";

import "./IsMobileHeader.scss";

export const IsMobileHeader = observer(
  ({
    appStore,
    dropStore,
    paths,
    locationPathname,
    popularServices,
    saveRedirectUrl,
    pathToFaq,
    isMobile,
    headerForDesktop,
    isDropdownListServices,
    toggleDropdownListServices,
    onCloseDropdownListServices,
  }) => {
    const isAuth = AppStore.isAuth;

    return (
      <div
        className={`header-mobile__inner ${paths.includes(locationPathname) ? "only-logo" : ""}`}>
        {!paths.includes(locationPathname) ? (
          <div className="header-mobile__container-up">
            <Link to="/"><div className="header-mobile__logo" /></Link>
            <div className="header-mobile__container-up_buttons">
              <ButtonWithIcon
                title="Помощь"
                className={`header-mobile__container-up_buttons-support ${isAuth ? "big-button" : ""}`}
                icon={supportIcon}
                ariaLabel="Кнопка технической поддержки"
                to={process.env.REACT_APP_SUPPORT_ADDRESS}
                onClick={() => {
                  metricsEvent(EVENTS.TELEGRAM_HELP);
                }}
              />
              {!isAuth &&
                <Link to="/signup">
                  <Button
                    title="Регистрация"
                    classes={["header-mobile__container-up_buttons-signup"]}
                    onClick={() => {
                      metricsEvent(EVENTS.SIGNUP_OPEN);
                      appStore.setIsFormModeLogin(false);
                      saveRedirectUrl();
                    }}
                  />
                </Link>
              }
            </div>
          </div>
        ) : (
          <a href="/">
            <div className="header-mobile__logo" />
          </a>
        )}

        {!paths.includes(locationPathname) && (
          <>
            <div
              className={`header-mobile__container-down ${
                !isAuth ? "together" : ""
              }`}
            >
              <a href={pathToFaq} className="header-mobile__link">
                <div
                  className="header-mobile__link-icon"
                  style={{
                    backgroundImage: `url(${faqIcon})`,
                  }}
                />
                FAQ
              </a>
              {/* <a href="/blog" className="header-mobile__link">
                <div
                  className={
                    "header-mobile__link-icon" +
                    (locationPathname === "/blog" ? " active" : "")
                  }
                  style={{
                    backgroundImage: `url(${
                      locationPathname === "/blog" ? blogIcon : blogIconDis
                    })`,
                  }}
                />
                Блог
              </a> */}
              <div className="header-mobile__dropdown-container">
                <button
                  className={
                    "header-mobile__link" +
                    (isDropdownListServices ? " active" : "")
                  }
                  type="button"
                  aria-label="Выбрать услугу"
                  onClick={toggleDropdownListServices}
                >
                  <div
                    className="header-mobile__link-icon"
                    style={{
                      backgroundImage: `url(${
                        isDropdownListServices ? closeIcon : servicesDisIcon
                      })`,
                      transition: "all 0.3s",
                    }}
                  />
                  Услуги
                </button>
                <DropdownLinks
                  isOpen={isDropdownListServices}
                  onClose={onCloseDropdownListServices}
                  links={popularServices}
                  isMobile={isMobile}
                  headerForDesktop={headerForDesktop}
                />
              </div>
              <a href={isAuth ? "/projects" : "/signin"} className="header-mobile__link">
                <div
                  className={
                    "header-mobile__link-icon" +
                    (locationPathname === "/project" ? " active" : "")
                  }
                  style={{
                    backgroundImage: `url(${
                      locationPathname === "/project"
                        ? projectsIcon
                        : projectsIconDis
                    })`,
                  }}
                />
                Проекты
              </a>

              {isAuth ? (
                <div
                  className="header-mobile__link"
                  onClick={() => {
                    dropStore.open();
                    appStore.setIsDropdownLinksOpen(false);
                  }}
                >
                  <div
                    className={
                      "header-mobile__link-icon" +
                      (dropStore.isActive ? " active" : "")
                    }
                    style={{
                      backgroundImage: `url(${
                        dropStore.isActive ? lkIcon : lkIconDis
                      })`,
                    }}
                  />
                  Профиль
                  <DropDownMenu
                    isMobile={isMobile}
                    headerForDesktop={headerForDesktop}
                  />
                </div>
              ) : (
                <Link to="/signin" className="header-mobile__link">
                  <div
                    className="header-mobile__link-icon"
                    style={{
                      backgroundImage: `url(${loginIcon})`,
                    }}
                  />
                  Войти
                </Link>
              )}
            </div>
          </>
        )}
      </div>
    );
  }
);
