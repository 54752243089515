import { observer } from "mobx-react";
import { useState } from "react";

import classNames from "classnames";

import ProjectsArchiveAbstractRow from "../ProjectsArchiveAbstractRow/ProjectsArchiveAbstractRow";
import ProjectLinkButton from "pages/Projects/components/ProjectLinkButton/ProjectLinkButton";
import { ProjectsActiveReportRow } from "pages/Projects/ProjectsActive/ProjectsActiveRow/ProjectsActiveReportRow/ProjectsActiveReportRow";
import { ButtonWithIcon } from "components/UI/ButtonWithIcon/ButtonWithIcon";
import Tooltips from "components/UI/Tooltips/Tooltips";
import FoldableSection from "components/UI/FoldableSection/FoldableSection";
import ProjectEditingTitle from "pages/Projects/components/ProjectSetupModal/ProjectEditingTitle/ProjectEditingTitle";

import AppStore from "AppStore";

import {
  getProjectReportsWithNotStatus,
  formatReportDateArchive,
  calcTotalProjectActions,
  findActivityCount,
} from "../../../Projects/ProjectsConstants";

import repeatIcon from "assets/images/repeat.svg";

import "./ProjectsArchiveRow.scss";

const ProjectsArchiveRow = observer(({ project }) => {
  const [expanded, setExpanded] = useState(false);
  const [renderReports, setRenderReports] = useState(false);

  function invertExpanded(e) {
    if (AppStore.editingTitle && AppStore.editingProjectId === project.id) {
      return;
    }
    if (e.target.closest(".project-editing-title__button-save")) {
      return;
    }

    setExpanded(!expanded);
    if (!expanded)
      setRenderReports(true);
  }

  if (!project) return null;

  const activities = project.activities;

  const actions = calcTotalProjectActions(project);
  const reviewsCount = findActivityCount(activities, "MAIN.REVIEW");
  const lastActivityDate = project.reports?.findLast(
    (report) => report.sentDatetime
  )?.sentDatetime;

  const statusMap = {
    finished: "Завершен",
    failed: "Ошибка",
  };

  const status = statusMap[project.status] || project.status;

  const onTransitionEnd = () => {
    if (!expanded)
      setRenderReports(false);
  };

  return (
    <FoldableSection className="projects-archive-row" expanded={expanded} onTransitionEnd={onTransitionEnd}>
      <ProjectsArchiveAbstractRow>
        <div className="projects-archive-row__title-container">
          <div className="projects-archive-row__title-section" onClick={invertExpanded}>
            <div
              className={`projects-archive-row__title-section-icon ${
                expanded ? "expanded" : ""
              }`}
            />
            {AppStore.editingTitle &&
            AppStore.editingProjectId === project.id ? (
              <ProjectEditingTitle project={project} />
            ) : (
              <Tooltips description={project.fullTitle}>
                <div className="projects-archive-row__title">
                  {project.titleToShow}
                </div>
              </Tooltips>
            )}
          </div>
          {AppStore.editingTitle && AppStore.editingProjectId === project.id ? (
            <></>
          ) : (
            <button
              type="button"
              aria-label="Редактировать"
              className="projects-archive-row__button-edit"
              onClick={() => {
                AppStore.startEditingProjectTitle(project.id);
                AppStore.setEditingTitle(true);
                AppStore.setNewTitle(project.title);
              }}
            />
          )}
        </div>
        <div className="projects-archive-row__info">
          <ProjectLinkButton style={{ visibility: project.link ? 'visible' : 'hidden' }} link={project.link} />
          <div
            className={classNames(
              "projects-archive-row__info-status",
              `projects-archive-row__info-status-${project.status}`
            )}
          >
            {status}
          </div>

          <div className="projects-archive-row__info-counter">
            <div className="projects-archive-row__info-counter-activity-all">
              <div className="projects-archive-row__info-counter-activity-all-icon" />
              {actions}
            </div>
            <div className="projects-archive-row__info-counter-activity-reviews">
              <div className="projects-archive-row__info-counter-activity-reviews-icon" />
              {reviewsCount}
            </div>
          </div>
          <div className="projects-archive-row__info-last-activity">
            {lastActivityDate
              ? formatReportDateArchive(new Date(lastActivityDate))
              : "—"}
          </div>
          <div className="projects-archive-row__info-city">
            {project.city ? project.city : "—"}
          </div>
        </div>
        <ButtonWithIcon
          title="Повторить"
          className="projects-archive-row__button-repeat"
          icon={repeatIcon}
          onClick={() => {
            AppStore.repeatProject(project);
          }}
          ariaLabel="Кнопка повторить проект"
        />
      </ProjectsArchiveAbstractRow>
      {renderReports && <div className="projects-archive-row__reports">
        {getProjectReportsWithNotStatus(project.reports, "new")?.map(
          (report, i) => (
            <ProjectsActiveReportRow
              key={i}
              project={project}
              report={report}
            />
          )
        )}
      </div>}
    </FoldableSection>
  );
});

export default ProjectsArchiveRow;
