import { observer } from "mobx-react";

import Faqed from "components/UI/Faqed/Faqed";
import InputLink from "components/InputLink/InputLink";
import Button from "components/UI/Button/Button";
import UploadFile from "components/UploadFile/UploadFile";
import { CategoryInput } from "../componentsStep1/CategoryInput/CategoryInput";
import { Phrase } from "../componentsStep1/Phrase/Phrase";
import CityInput from "../componentsStep1/CityInput/CityInput";

import AppStore from "AppStore";

import "./IsMobileStep1.scss";

export const IsMobileStep1 = observer(
  ({
    store,
    needFullParams,
    nameSite,
    linkRef,
    categoryRef,
    cityRef,
    searchPhrasesRef,
    screenshotRef,
    onChangeLinkToAd,
    onChangeAddress,
    onClickCreateProject,
    descriptionScreenshot,
    descriptionCategory,
    descriptionCity,
    iconUploadFile,
  }) => {
    return (
      <div className="market-step1-mobile">
        <div className="market-step1-mobile__link_settings">
          {needFullParams && (
            <div className={`market-step1-mobile__link_settings-screen ${AppStore.uploadedFileError ? " error" : ""}`} ref={screenshotRef}>
              <Faqed text={descriptionScreenshot}>
                <p className="market-step1-mobile__label">
                  Прикрепите скриншот объявления
                </p>
              </Faqed>
              <UploadFile 
                title="Прикрепите скриншот"
                icon={iconUploadFile} 
                className="avito-upload-file__add-button" 
              />
            </div>
          )}
          <div ref={linkRef}>
            <Faqed text="Укажите ссылку формата https://">
              <p className="market-step1-mobile__label">
                {store.market.targetLinkCommentShort}
              </p>
            </Faqed>
            <InputLink
              defaultValue={store.linkToAd}
              onChange={onChangeLinkToAd}
              errortext={store.linkError}
            />
          </div>
          {needFullParams && (
            <>
              <div ref={categoryRef}>
                <Faqed text={descriptionCategory}>
                  <p className="market-step1-mobile__label">
                    Укажите категорию продвижения поисковой фразы
                  </p>
                </Faqed>
                <CategoryInput store={store} error={store.categoryError} />
              </div>
              <div ref={cityRef}>
                <Faqed text={descriptionCity}>
                  <p className="market-step1-mobile__label">
                    Укажите город продвижения поисковой фразы
                  </p>
                </Faqed>
                <CityInput onChange={onChangeAddress} error={store.cityError} />
              </div>
              <div ref={searchPhrasesRef}>
                <p className="market-step1-mobile__label">
                  Какие поисковые фразы надо продвинуть на {nameSite}?
                </p>
                <p className="market-step1-mobile__note">
                  Делайте более <span>точные поисковые запросы</span>{" "}
                  и не используйте запросы с одним поисковым словом
                </p>
                <Phrase store={store} />
              </div>
            </>
          )}
          <Button
            className="market-step1-mobile__button-next"
            title="Создать проект"
            onClick={onClickCreateProject}
          />
        </div>
      </div>
    );
  }
);
