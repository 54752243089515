import { observer } from "mobx-react";

import ProjectEditingScreen from "./ProjectEditingScreen/ProjectEditingScreen";
import ProjectEditingLink from "./ProjectEditingLink/ProjectEditingLink";
import UploadFile from "components/UploadFile/UploadFile";
import Button from "components/UI/Button/Button";
import { ButtonWithIcon } from "components/UI/ButtonWithIcon/ButtonWithIcon";

import AppStore from "AppStore";
import messageBoxStore from "MessageBox/MessageBoxStore";

import replacementscreen from "assets/images/replacementscreen.svg";
import linkIcon from "assets/images/link.svg";

import "./ProjectSetupModal.scss";

const ProjectSetupModal = observer(({ project, link, onClose }) => {
  const canUpdateScreenshot = project.screenshot === true;
  const hasLink = !!project.link;

  const handleSaveNewDetailsProject = async () => {
    if (!project) return;

    const updateData = {};

    if (AppStore.newLink !== "" && AppStore.newLink !== link) {
      updateData.link = AppStore.newLink;
    }

    if (AppStore.uploadedFiles.default) {
      const screenshot = await AppStore.computeScreenshotBlob(AppStore.uploadedFiles.default);
      updateData.screenshot = screenshot;
    }

    if (Object.keys(updateData).length > 0) {
      const success = await AppStore.updateProjectDetails(project, updateData);
      if (success) {
        onClose();
      }
    } else {
      messageBoxStore.showError("Изменения проекта не были внесены");
      onClose();
    }
  };

  return (
    <div className="project-setup">
      <div className="project-setup__vertical">
        <div className="project-setup__vertical-icon" />
        <div className="project-setup__vertical-line" />
      </div>

      <div className="project-setup__container">
        <p className="project-setup__title">Настройки проекта</p>

        <div className="project-setup__config">
          <ProjectEditingScreen store={AppStore} project={project} />

          <div className="project-setup__settings">
            {hasLink &&
              <ProjectEditingLink
                store={AppStore}
                project={project}
                link={link}
              />
            }
            <div className="project-setup__buttons">
              <UploadFile
                className="project-setup__button-setting-up"
                title="Заменить скриншот"
                icon={replacementscreen}
                showSuccess={false}
                alwaysShowButton={true}
                disabled={!canUpdateScreenshot}
              />
              {hasLink &&
                <ButtonWithIcon
                  title="Заменить ссылку"
                  className="project-setup__button-setting-up"
                  icon={linkIcon}
                  ariaLabel="Заменить ссылку"
                  onClick={() => AppStore.startEditingNewLink(project)}
                />
              }
            </div>
            <div className="project-setup__buttons">
              <Button
                title="Отмена"
                className="project-setup__button-cancel"
                onClick={() => onClose()}
              />
              <Button
                title="Сохранить изменения"
                className="project-setup__button-save"
                onClick={handleSaveNewDetailsProject}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});

export default ProjectSetupModal;
