import { observer } from "mobx-react";
import { useEffect, useState } from "react";
import { useInView } from "react-intersection-observer";

import { ActivitiesCalculator } from "../componentsStep2/ActivitiesCalculator/ActivitiesCalculator.jsx";
import ModeSelection from "../componentsStep2/ModeSelection/ModeSelection.jsx";
import { BudgetSlider } from "components/BudgetSlider/BudgetSlider.jsx";
import Deadline from "../componentsStep2/Deadline/Deadline.jsx";
import Faqed from "components/UI/Faqed/Faqed.jsx";

import { getCountActions, getCountDays } from "utils/helpers.js";

import "./IsMobileStep2.scss";

export const IsMobileStep2 = observer(
  ({
    store,
    calculator,
    pathname,
    reviewRef,
    commentRef,
    getDisplayCount,
    getAllActionCount,
    onClickNext,
  }) => {
    const [active, setActive] = useState(false);
    const { ref, inView } = useInView({ threshold: 0.2 });
    const { market, mode } = calculator;

    useEffect(() => {
      if (inView) {
        setActive(true);
      } else {
        setActive(false);
      }
    }, [inView])

    return (
      <div className="market-step2-mobile">
        {store.calculators.length > 1 && <ModeSelection store={store} />}
        <div className="market-step2-mobile__distribution">
          <div className="market-step2-mobile__distribution-icon" style={{ backgroundImage: `url(${market.icon})` }} />
          <Faqed text={mode.stepTwoTitleFaq}>
            <h3 className="market-step2-mobile__distribution-title">
              {mode.calculatorTitle}
            </h3>
          </Faqed>
        </div>
        <div ref={ref}>
          <ActivitiesCalculator
            store={store}
            reviewRef={reviewRef}
            commentRef={commentRef}
          />
        </div>

        <div className={`market-step2-mobile__settings ${active ? "fixed-bottom" : ""}`}>
          <div className="market-step2-mobile__budget-slider-container">
            <div className="market-step2-mobile__budget-slider-action">
              <BudgetSlider store={store} onClickNext={onClickNext}>
                <p className="market-step2-mobile__project-price-info">
                  {`${store.currentCalculator.price} ₽`}
                </p>
                <div className="market-step2-mobile__project-info">
                  <div className="market-step2-mobile__project-info_action">
                    <p>≈</p>
                    <div className="market-step2-mobile__project-info_action-icon" />
                    <p>{`${getAllActionCount(store.currentCalculator, pathname)} ${getCountActions(getAllActionCount(store.currentCalculator, pathname))}`}</p>
                  </div>
                  {getDisplayCount(store.currentCalculator, pathname) && (
                    <div className={`market-step2-mobile__project-info_reviews ${mode.actions.find((action) =>action.type.includes("REVIEW")) ? "": "hidden"}`}>
                      <p>≈</p>
                      <div className="market-step2-mobile__project-info_reviews-icon" />
                      <p>{getDisplayCount(store.currentCalculator, pathname)}</p>
                    </div>
                  )}
                </div>
              </BudgetSlider>
            </div>
          </div>
        </div>
        <div className="market-step2-mobile__deadline">
          <p className="market-step2-mobile__deadline-title">
            Выберите скорость отправки заданий исполнителям:
          </p>
          <div className="market-step2-mobile__deadline-choice">
            {store.currentCalculator.deadlines.map((deadline, i) => (
              <Deadline
                key={i}
                deadline={`${deadline.days} ${getCountDays(deadline.days)}`}
                title={deadline.title}
                isActive={i === store.selectedDeadlineIndex}
                onClick={() => {
                  store.selectDeadline(i);
                }}
              />
            ))}
          </div>
        </div>
      </div>
    );
  }
);
