import { observer } from "mobx-react";
import { useLocation } from "react-router-dom";

import Plate from "components/UI/Plate/Plate";
import { IsMobileStep2 } from "./IsMobileStep2/IsMobileStep2.jsx";
import { IsDesktopStep2 } from "./IsDesktopStep2/IsDesktopStep2.jsx";

import AppStore from "AppStore";

import { useMatchMedia } from "utils/hooks/use-match-media";
import { getCountReviews } from "utils/helpers.js";

import { EVENTS , metricsEvent } from "metrics";

import "./Step2.scss";

export const Step2 = observer(
  ({ store, reviewRef, commentRef, scrollToError, nextStep }) => {
    const { isMobile } = useMatchMedia();
    const { pathname } = useLocation();
    const calculator = AppStore.currentMarketStore.currentCalculator;

    function onClickNext() {
      if (!store.validateStep2()) {
        scrollToError(reviewRef);
        return;
      }

      metricsEvent(EVENTS.PROJECT_STEP);

      if (nextStep?.current) {
        const headerHeight = 80;
        const yOffset =
          nextStep.current.getBoundingClientRect().top +
          window.scrollY -
          headerHeight;
        window.scrollTo({ top: yOffset, behavior: "smooth" });
      }
    }

    const getDisplayCount = (calculator, pathname) => {
      return `${calculator.reviewsCount} ${getCountReviews(
        calculator.reviewsCount
      )}**`;
    };

    const getAllActionCount = (calculator, pathname) => {
      return calculator.actionsCount;
    };

    return (
      <Plate className="market-step2-plate">
        {isMobile ? (
          <IsMobileStep2
            store={store}
            calculator={calculator}
            pathname={pathname}
            nextStep={nextStep}
            reviewRef={reviewRef}
            commentRef={commentRef}
            scrollToError={scrollToError}
            getDisplayCount={getDisplayCount}
            getAllActionCount={getAllActionCount}
            onClickNext={onClickNext}
          />
        ) : (
          <IsDesktopStep2
            store={store}
            calculator={calculator}
            pathname={pathname}
            nextStep={nextStep}
            reviewRef={reviewRef}
            commentRef={commentRef}
            scrollToError={scrollToError}
            getDisplayCount={getDisplayCount}
            getAllActionCount={getAllActionCount}
            onClickNext={onClickNext}
          />
        )}
      </Plate>
    );
  }
);
