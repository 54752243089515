import { observer } from "mobx-react";

import MarketItem from "../MarketItem/MarketItem";

import { MARKETS } from "AppStore";
import { getCountPlatforms } from "utils/helpers";

import "./PlatformsSection.scss";

const PlatformsSection = observer(({ store, isMobile }) => {
  const platformCount = MARKETS.length;

  return (
    <section className="main__platforms">
      <div className="main__platforms_banner">
        <p className="main__platforms_banner-title">Другие <span className="main__platforms_banner-title-span">площадки</span> <br /> для вашего PR</p>
        <p className="main__platforms_banner-text">Пока только <span className="main__platforms_banner-text-span">{platformCount} {getCountPlatforms(platformCount)},</span> <br /> но совсем скоро их будет намного {!isMobile && <br />} больше!</p>
        <div className="main__platforms_banner-bg" />
        <div className="main__platforms_banner-bg" />
        <div className="main__platforms_banner-bg" />
        <div className="main__platforms_banner-bg" />
        <div className="main__platforms_banner-bg" />
        <div className="main__platforms_banner-bg" />
        <div className="main__platforms_banner-bg" />
      </div>

      <div className="main__platforms-container">
        {!isMobile &&
          <div className="main__platforms_text">
            <p>В планах более 50 площадок от Авито до Яндекс.Музыки</p>
            <div className="main__platforms_icon" />
          </div>
        }

        <div className="main__platforms_items">
          <div className="main__platforms_markets-container">
            {MARKETS.map((market, index) => {
              const defaultPath = market.modes[0]?.path;

              return (
                <MarketItem
                  key={index}
                  market={market}
                  index={index}
                  linkTo={defaultPath}
                  onClick={() => { store.selectMarketStoreAndCalculatorByPath(defaultPath); window.scrollTo(0, 0)}}
                  containerClassName="main__platforms_item"
                  itemClassName="main__platforms_item-info"
                  logoClassName="main__platforms_item-logo"
                  titleClassName="main__platforms_item-label"
                  showArrow={true}
                  showBadge={true}
                  isSelected={true}
                  showBadgeStatus={market.statusForHomePage}
                />
              );
            })}
          </div>
        </div>
      </div>
    </section>
  )
});

export default PlatformsSection;